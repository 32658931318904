import ApiService from './ApiService'

export async function apiSignIn(data, type = '') {
    let hiturl = ''
    if (type == 'assessor') {
        hiturl = '/assessor_login'
    } else if (type == 'school') {
        hiturl = '/school_login'
    } else if (type == 'teacher') {
        hiturl = '/teacher-login'
    } else if (type == 'login_qc') {
        hiturl = '/login_qc'
    }
     else {
        hiturl = '/login'
    }
    return ApiService.fetchData({
        url: hiturl,
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
