import { createServer } from 'miragejs'
import appConfig from 'configs/app.config'
import { notificationListData, searchQueryPoolData } from './data/commonData'

import { usersData, userDetailData } from './data/usersData'
import { eventsData, mailData, crmDashboardData } from './data/crmData'

import {
    settingData,
    settingIntergrationData,
    settingBillingData,
    invoiceData,
    logData,
    accountFormData,
} from './data/accountData'

import { signInUserData } from './data/authData'

import { commonFakeApi, accountFakeApi, authFakeApi } from './fakeApi'

const { apiPrefix } = appConfig

export default function mockServer({ environment = 'test' }) {
    return createServer({
        environment,
        seeds(server) {
            server.db.loadData({
                notificationListData,
                searchQueryPoolData,
                usersData,
                userDetailData,
                eventsData,
                mailData,
                settingData,
                settingIntergrationData,
                settingBillingData,
                invoiceData,
                logData,
                accountFormData,
                signInUserData,
                crmDashboardData,
            })
        },
        routes() {
            this.urlPrefix = ''
            this.namespace = ''
            this.passthrough((request) => {
                let isExternal = request.url.startsWith('http')
                return isExternal
            })
            this.passthrough()

            commonFakeApi(this, apiPrefix)
            // projectFakeApi(this, apiPrefix)
            // crmFakeApi(this, apiPrefix)
            // salesFakeApi(this, apiPrefix)
            accountFakeApi(this, apiPrefix)
            authFakeApi(this, apiPrefix)
            // cryptoFakeApi(this, apiPrefix)
            // knowledgeBaseFakeApi(this, apiPrefix)
        },
    })
}
