import Cookies from "js-cookie";

const userRole = Cookies.get('role');
console.log('userRole', userRole);

let unAuthenticatedEntryPath = '/sign-in'; // Default path

if (userRole) {
    switch (userRole) {
        case 'assessor':
            unAuthenticatedEntryPath = '/assessor-login';
            break;
        case 'School':
            unAuthenticatedEntryPath = '/school-login';
            break;
        case 'qc':
            unAuthenticatedEntryPath = '/qc-login';
            break;
        case 'admin':
        case 'LeadA':
            unAuthenticatedEntryPath = '/sign-in';
            break;
        default:
            unAuthenticatedEntryPath = '/sign-in';
            break;
    }
}

const appConfig = {
    apiPrefix: process.env.REACT_APP_API_BASE || '/api',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: unAuthenticatedEntryPath,
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
};

export default appConfig;
