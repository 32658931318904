
import BaseService from './BaseService'


const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            console.log('hi')
            BaseService(param)
                .then((response) => {
                    console.log('by',response)
                    resolve(response)
                    // <Link to={`assessment-ongoing?teacher=${response.data.teacher_id}&assessment=${response.data.assessment_id}`}></Link>
                    // navigate()
                    // Cookies.set('assessment_id',response.data.assessment_id)

                })
                .catch((errors) => {
                    reject(errors)
                })
        })
    },
}

export default ApiService
