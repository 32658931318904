// import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import Cookies from 'js-cookie'
import Log from 'views/account/ActivityLog/components/Log'
import { useDispatch, useSelector } from 'react-redux'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values, type) => {
        try {
            const resp = await apiSignIn(values, type)
            if (resp.data) {
                console.log(resp.data, 'logindata')
                Cookies.set('email', resp.data.email, { expires: 5 / 24 });
                // Cookies.set('email', resp.data.email, { expires: 10 / 1440 });
                Cookies.set('name', resp.data.name)
                Cookies.set('role', resp.data.role)
                Cookies.set('company_id', resp.data.company_id)
                Cookies.set('parent_company', resp.data.parent_company)
                Cookies.set('company_type', resp.data.company_type)
                Cookies.set('company_name', resp.data.company_name)
                Cookies.set('user_id', resp.data.user_id)
                Cookies.set('teacher_id', resp.data.teacher_id)
                Cookies.set('assessment_id', resp.data.assessment_id)
                const { token } = resp.data

                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                console.log('redirectUrl', redirectUrl)
                if (resp.data.authority[0] == 'teacher') {
                    navigate(
                        `/teachers-assessment-ongoing?teacher=${resp.data.teacher_id}&assessment=${resp.data.assessment_id}`
                    )
                } else {
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : appConfig.authenticatedEntryPath
                    )
                }
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data

                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
        // const userRole = Cookies.get('role');
        // console.log('userRole', userRole);

        // switch (userRole) {
        //     case 'school':
        //         navigate('/school-login');
        //         break;
        //     case 'qc':
        //         navigate('/qc-login');
        //         break;
        //     case 'admin':
        //     case 'la':
        //         navigate('/sign-in');
        //         break;
        //     default:
        //         navigate('/sign-in');
        //         break;
        // }
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
